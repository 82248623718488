import Layout from '@components/layout';
import './style.css';

import Tabs from '@components/tabs';
import SwipeableComponent from '@components/swipeable';
import { useEffect, useState } from 'react';
import Buttom from '@components/buttons';
import { useStake } from '@hooks/useStake';
import { useLocation } from 'react-router-dom';

function App() {
  const location = useLocation();
  const { boost_stake_id } = location.state || {};

  const {
    stakeSummary,
    getStakeSummary,
    stakeFinish,
    getStakeFinish,
    cancel,
    boost,
  } = useStake();

  useEffect(() => {
    getStakeSummary();
    getStakeFinish();
  }, []);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const Started: React.FC<any> = () => {
    return (
      <>
        {stakeSummary?.stake_actives.map((item, index) => {
          const formattedStakeAmount = item.stake_started.amount
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/);

          const realTimeBalance = formattedStakeAmount
            ? parseFloat(formattedStakeAmount[0])
            : 0;

          const balance = realTimeBalance + parseFloat(item.current_reward);

          const formattedStakeBalance = balance
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/);

          const realTimeBalanceCurrent = formattedStakeBalance
            ? parseFloat(formattedStakeBalance[0])
            : 0;

          const totalBalance =
            realTimeBalance + parseFloat(item.total_reward_estimated);

          const formattedTotalBalance = totalBalance
            .toString()
            .match(/^-?\d+(?:\.\d{0,2})?/);

          const cancelStake = () => {
            const isConfirmed = window.confirm(
              'Are you sure you want to cancel this stake?\n*You will lose the rewards obtained so far',
            );

            if (isConfirmed) {
              const data = {
                stake_id: item.stake_started.id,
              };
              cancel(data);
            } else {
              console.log('Cancelado');
            }
          };

          const boostStake = () => {
            const isConfirmed = window.confirm(
              'Are you sure you want to boost this stake?',
            );

            if (isConfirmed) {
              const data = {
                stake_id: item.stake_started.id,
                boost_id: boost_stake_id,
              };
              boost(data);
            } else {
              console.log('Cancelado');
            }
          };

          return (
            <div key={index} style={{ marginBottom: 100 }}>
              <p>
                <b>{item.stake_started.contract_day}d</b>
              </p>

              <div className="stake-info">
                <div className="stake-info-item">
                  <div className="stake-info-label">Real time balance</div>
                  <div className="stake-info-value">
                    {realTimeBalanceCurrent} USDT
                  </div>
                </div>

                <div className="stake-info-item">
                  <div className="stake-info-label">Expected final balance</div>
                  <div className="stake-info-value">
                    {formattedTotalBalance
                      ? parseFloat(formattedTotalBalance[0])
                      : 0}
                    &nbsp;USDT
                  </div>
                </div>
              </div>
              <br />
              <div className="btn-cancel">
                {boost_stake_id ? (
                  <Buttom label="Boost Stake" size="lg" onClick={boostStake} />
                ) : (
                  <Buttom
                    label="Cancel Stake"
                    size="lg"
                    onClick={cancelStake}
                  />
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const Finished: React.FC = () => {
    return (
      <div className="stake-info">
        {stakeFinish.map((item: any, index: any) => (
          <div className="stake-info-item" key={index}>
            <div className="stake-info-label">
              <span className="stake-info-icon"></span> {item.day}d
            </div>
            <div className="stake-info-value">+ {item.amount} USDT</div>
            <div className="stake-info-value">{item.date}</div>
          </div>
        ))}
      </div>
    );
  };

  const tabs = [
    {
      title: 'Started',
    },
    {
      title: 'Finished',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [<Started />, <Finished />];

  return (
    <Layout showNotification={false} showNavMobile={false}>
      <div id="stake-summary">
        <div id="content-tabs-stake">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />
        </div>

        <SwipeableComponent
          currentItem={activeSwipeView}
          components={components}
          onIndexChange={handleIndexChange}
        />
      </div>
    </Layout>
  );
}

export default App;
