import { userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';
import { AuthProps } from 'types/auth';

export const authLogin = async (data: AuthProps) => {
  try {
    const referCode = localStorage.getItem('refercode');
    data.referCode = referCode;
    const response = await requests.post('auth', data);
    localStorage.setItem('@tokenAuth', response.data.access_token);
    localStorage.setItem('@userData', JSON.stringify(response.data.user));
    store.dispatch(
      userData({
        id: response.data.id,
        username: response.data.user.name,
        photo_profile: response.data.user.photo_profile,
        provider: response.data.user.provider,
        provider_user_id: response.data.user.provider_user_id,
        liking_account: response.data.user.liking_account ?? false,
        user_code: response.data.user.user_code ?? false,
        addresswallet: '',
      }),
    );
    return response.data;
  } catch (error: any) {
    console.log({ error });
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const linkingAccount = async (data: AuthProps) => {
  try {
    const response = await requests.post('liking/account', data);

    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};
