import Layout from '@components/layout';
import './style.css';
import Buttom from '@components/buttons';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { useEffect, useState } from 'react';
import { useGame } from '@hooks/useGame';

function App() {
  const REACT_APP_URI = process.env.REACT_APP_URI;

  const { user, miniapp } = useSelector(
    (state: AppStateType) => state.initLoad,
  );

  const { getUncalimedPoints, claimPoints } = useGame();
  const [userCode] = useState<string>(
    miniapp
      ? `t.me/krlosexe_bot/Krlosexe/?startapp=${user.user_code}`
      : `${REACT_APP_URI}/?refercode=${user.user_code}`,
  );

  const [totalpoints, setTotalPoints] = useState(0);

  useEffect(() => {
    getUncalimedPoints().then((data) => {
      if (data.total_points) {
        setTotalPoints(parseInt(data.total_points));
      } else {
        setTotalPoints(0);
      }
    });
  }, []);

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(userCode)
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Error al copiar el enlace: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = userCode;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      alert('Link copied to clipboard!');
    }
  };

  const claim = () => {
    const isConfirmed = window.confirm('Are you sure to perform this action?');

    if (isConfirmed) {
      claimPoints().then(() => {
        getUncalimedPoints().then((data) => {
          if (data.total_points) {
            setTotalPoints(parseInt(data.total_points));
          } else {
            setTotalPoints(0);
          }
        });
      });
    } else {
      console.log('Cancelado');
    }
  };

  return (
    <Layout
      icon={false}
      showNavMobile={false}
      showBottomNav={true}
      showGoBack={true}
    >
      <div id="frens">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">
              Unclaimed Points: {totalpoints}
            </span>
          </div>
        </div>
        <div className="btn-claim">
          <Buttom
            size="lg"
            label="CLAIM ALL"
            onClick={claim}
            disabled={totalpoints === 0 ? true : false}
          />
        </div>
        <br />
        <br />

        <div className="btn-copy" onClick={handleCopy}>
          <h3>Referral Link</h3>
        </div>
      </div>
    </Layout>
  );
}

export default App;
