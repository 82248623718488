import requests from 'helpers/requests';
import { useEffect, useState } from 'react';
import { useToast } from '@context/ToastContext';

export const useReferrals = () => {
  const { showToast } = useToast();
  const [data, setData] = useState<any>([]);
  const [balance, setBalance] = useState<any>(0.0);

  const getdata = async () => {
    try {
      const response = await requests.get('get/referrals');
      setData(response.data);
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  const getBalance = async () => {
    try {
      const response = await requests.get('refferals/balance');
      setBalance(response.data.balance);
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  const claimComission = async (data: any) => {
    try {
      await requests.post('claim/comission', data);
      getdata();
      getBalance();
      showToast('successful operation', 'success');
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  const claimComissionAll = async () => {
    try {
      await requests.post('claim/comission/all', {});
      getdata();
      getBalance();
      showToast('successful operation', 'success');
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  useEffect(() => {
    getdata();
    getBalance();
  }, []);

  return {
    data,
    balance,
    refresh: getdata,
    claimComission,
    claimComissionAll,
    getBalance,
  };
};
