import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Splash from '@components/splash';

import useScrollToTop from 'hooks/useScrollTopNav';
import { useInit } from 'hooks/useInit';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import useFirebase from 'hooks/useFirebase';
import {
  FrensScreen,
  HomeScreen,
  SingInScreen,
  SingUnScreen,
  StakeScreen,
  WalletScreen,
  LikingAccounts,
  LaunchScreen,
  StakeSummaryScreen,
  TransferScreen,
  TradeScreen,
  AirDropScreen,
  FrensPoints,
  DepositScreen,
  WithdrawScreen,
} from '@screens/index';
import LeftMenu from '@components/leftMenu';

const RootNavigator = () => {
  useInit();
  useFirebase();

  const { isFirstLoad, isSingin } = useSelector(
    (state: AppStateType) => state.initLoad,
  );

  return (
    <>
      {isFirstLoad ? (
        <Splash />
      ) : (
        <Router>
          <ScrollToTop />

          {!isSingin ? (
            <Routes>
              <Route path="/" element={<LaunchScreen />} />
              <Route path="/sing-in" element={<SingInScreen />} />
              <Route path="/sing-up" element={<SingUnScreen />} />
            </Routes>
          ) : (
            <div className="content-layout">
              <div className="layout-nav-menu">
                <LeftMenu />
              </div>
              <div className="content-layout-child">
                <Routes>
                  <Route path="*" element={<Navigate to="/" replace />} />
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="/dashboard" element={<HomeScreen />} />
                  <Route path="/stake" element={<StakeScreen />} />
                  <Route path="/wallet" element={<WalletScreen />} />
                  <Route path="/transfer" element={<TransferScreen />} />
                  <Route path="/frens" element={<FrensScreen />} />
                  <Route path="/trade" element={<TradeScreen />} />
                  <Route path="/airdrop" element={<AirDropScreen />} />
                  <Route path="/frenspoints" element={<FrensPoints />} />
                  <Route path="/deposit" element={<DepositScreen />} />
                  <Route path="/withdraw" element={<WithdrawScreen />} />

                  <Route
                    path="/linking-accounts"
                    element={<LikingAccounts />}
                  />
                  <Route
                    path="/stake-summary"
                    element={<StakeSummaryScreen />}
                  />
                </Routes>
              </div>
            </div>
          )}
        </Router>
      )}
    </>
  );
};

const ScrollToTop: React.FC = () => {
  useScrollToTop();
  return null;
};
export default RootNavigator;
