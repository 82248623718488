import { useEffect } from 'react';

import {
  isFirstLoad,
  isSingin,
  setMiniApp,
  setShowLogout,
  userData,
} from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';
import { authLogin } from 'hooks/useAuth';

interface TelegramWebAppUser {
  id: number;
  first_name: string;
  last_name: string;
  username?: string;
  photo_url?: string;
}

export const useInit = () => {
  let miniapp = false;
  const getInitData = async () => {
    console.log('get init data');
    // store.dispatch(isFirstLoad(true));
    // store.dispatch(isSingin(false));

    if (window.Telegram && window.Telegram.WebApp) {
      if (window.Telegram.WebApp.initData) {
        miniapp = true;
        store.dispatch(setMiniApp(true));
        store.dispatch(setShowLogout(false));
        const initData = window.Telegram.WebApp.initData;

        const telegram = window.Telegram.WebApp;

        //@ts-ignore
        const initDataUnsafe = telegram.initDataUnsafe;
        const startapp = initDataUnsafe.start_param;

        localStorage.setItem('refercode', startapp);

        const urlParams = new URLSearchParams(initData);

        const userData = JSON.parse(
          urlParams.get('user') as string,
        ) as TelegramWebAppUser;

        const userId = userData.id;
        const authDate = urlParams.get('auth_date');
        const hash = urlParams.get('hash');

        //  alert(startapp);
        const data = {
          provider: 'telegram',
          provider_user_id: userId,
          name: `${userData.first_name} ${userData.last_name}`,

          provider_data: {
            id: userId,
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            photoProfile: userData.photo_url,
            photo_url: userData.photo_url,
            auth_date: authDate ?? 0,
            hash: hash ?? '',
            startapp,
          },
        };

        authLogin(data)
          .then((response) => {
            verifyAuth();
          })
          .catch((error: any) => {
            alert(error);
            console.log('error', error);
          });
      } else {
        verifyAuth();
      }
    } else {
      console.log('Telegram WebApp is not available');
    }
    verifyAuth();
  };

  const verifyAuth = async () => {
    try {
      const response = await requests.post('verifyAuth');

      store.dispatch(isSingin(true));
      store.dispatch(isFirstLoad(false));

      localStorage.setItem('@userData', JSON.stringify(response.data));
      store.dispatch(
        userData({
          id: response.data.id,
          username: response.data.name,
          photo_profile: response.data.photo_profile,
          provider: response.data.provider,
          provider_user_id: response.data.provider_user_id,
          liking_account: response.data.liking_account ?? false,
          user_code: response.data.user_code ?? false,
          addresswallet: response.data.addresswallet,
        }),
      );
    } catch (error: any) {
      if (error.response.status !== 401 || !miniapp) {
        store.dispatch(isFirstLoad(false));
        store.dispatch(isSingin(false));
        localStorage.removeItem('@userData');
        localStorage.removeItem('@tokenAuth');
        console.error('Error verifying authentication:', error);
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getInitData();
    }, 3000);
  }, []);
};
