import './style.css';
import Layout from '@components/layout';
import { AppStateType } from '@redux/store';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';

type typeOptions = 'Select Network' | 'BEP-20';

function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<typeOptions>('Select Network');

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option: typeOptions) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const handleCopy = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(user.addresswallet)
        .then(() => {
          alert('Address copied to clipboard!');
        })
        .catch((err) => {
          console.error('Error al copiar el enlace: ', err);
        });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = user.addresswallet;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      alert('Address copied to clipboard!');
    }
  };

  return (
    <Layout
      title="DEPOSIT"
      icon={false}
      showBottomNav={true}
      showNavMobile={false}
      showNotification={false}
    >
      <div id="deposit">
        <div className="content-deposit">
          <div className="dropdown-deposit" onClick={toggleDropdown}>
            <span>{selectedOption}</span>
            <i className="fas fa-chevron-down"></i>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <div
                  onClick={() => handleOptionSelect('BEP-20')}
                  className="dropdown-item"
                >
                  BEP-20
                </div>
              </div>
            )}
          </div>
          <br />
          <br />

          {selectedOption === 'BEP-20' && (
            <div>
              <QRCode
                value={user.addresswallet}
                style={{
                  width: '20%',
                  height: 100,
                  margin: '0 auto',
                  display: 'flex',
                }}
              />
              <br />
              <div className="btn-copy" onClick={handleCopy}>
                <p className="wallet-address">{user.addresswallet}</p>
                <i className="fas fa-copy"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default App;
