import requests from 'helpers/requests';
import { useEffect, useState } from 'react';
import { Wallet } from 'types/wallet';
import { useToast } from '@context/ToastContext';

export const useWallet = () => {
  const { showToast } = useToast();

  const [data, setData] = useState<Wallet>();
  const [withDrawData, setWithDraw] = useState<Wallet>();

  type typeOptions = 'Wallet' | 'Stake' | 'Referrals';
  const get = async () => {
    try {
      const response = await requests.get('wallet');
      setData(response.data);
    } catch (error: any) {}
  };

  const getWithDraw = async () => {
    try {
      const response = await requests.get('withdraw');
      setWithDraw(response.data);
    } catch (error: any) {}
  };

  const transfer = async (data: {
    amount: number | string;
    from: typeOptions;
    to: typeOptions;
  }) => {
    try {
      const response = await requests.post('transfer', data);
      return response;
    } catch (error: any) {
      showToast('An error occurred', 'error');
      return error;
    }
  };

  const withDraw = async (data: {
    amount: number | string;
    address: string;
  }) => {
    try {
      const response = await requests.post('withdraw', data);
      return response;
    } catch (error: any) {
      throw new Error('An unknown error occurred');
    }
  };

  useEffect(() => {
    get();
    getWithDraw();
  }, []);

  return { data, transfer, refresh: get, withDraw, withDrawData };
};
