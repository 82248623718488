import requests from 'helpers/requests';

export const getTasks = async (provider: string) => {
  try {
    const payload = {
      provider,
    };
    const response = await requests.get('get/task', { params: payload });
    return response.data;
  } catch (error: any) {}
};

export const verifyTask = async (data: {
  provider: string;
  provider_user_id: string;
}) => {
  try {
    const response = await requests.post('verify/task', data);
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const checkAllTasks = async () => {
  try {
    const response = await requests.get('get/all/task/done');
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const activateReward = async () => {
  try {
    const response = await requests.get('active/task/reward');
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};

export const getBoost = async () => {
  try {
    const response = await requests.get('task/boost');
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};
