import requests from 'helpers/requests';
import { useEffect, useState } from 'react';

export const useGame = () => {
  const [points, setPoints] = useState<number>(0);
  const [availableTickets, setavailableTickets] = useState<any>(null);

  const getPoints = async () => {
    try {
      const response = await requests.get('game/get/points');
      setPoints(response.data);
    } catch (error: any) {}
  };
  const getTicketsAvailable = async () => {
    try {
      const response = await requests.get('get/tickets/available');
      setavailableTickets(response.data);
    } catch (error: any) {}
  };
  const usedTicket = async () => {
    try {
      await requests.post('used/tickets/game');
      getTicketsAvailable();
    } catch (error: any) {}
  };

  const getUncalimedPoints = async () => {
    try {
      const response = await requests.get('game/get/points/unclaimed');
      return response.data;
    } catch (error: any) {}
  };

  const claimPoints = async () => {
    try {
      await requests.post('game/claim/points');
      return true;
    } catch (error: any) {}
  };

  useEffect(() => {
    getPoints();
    getTicketsAvailable();
  }, []);

  return {
    points,
    refresh: getPoints,
    availableTickets,
    usedTicket,
    getUncalimedPoints,
    claimPoints,
  };
};
