import LogoWhite from '@assets/logos/white.svg';
import './style.css';
import Buttom from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  return (
    <div className="launch-container">
      <div className="line-container">
        <div className="line-horizontal"></div>
        <div className="line-horizontal secondary"></div>
        <div className="line-vertical"></div>
      </div>

      <div className="social-network">
        <i className="fab fa-telegram-plane"></i>
        <i className="fab fa-twitter"></i>
        <i className="fab fa-instagram-square"></i>
        <i className="fab fa-youtube"></i>
        <i className="fab fa-bitcoin"></i>
      </div>
      <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
      <br />
      <p>The most innovative and profitable staking options in a one app</p>

      <div className="launch-btns">
        <div>
          <Buttom
            size="lg"
            label="Desktop Launch"
            onClick={() => {
              navigate('/sing-in');
            }}
          />
        </div>
        <div>
          <Buttom
            size="lg"
            label="Telegram Launch"
            onClick={() => {
              window.location.href = 'https://t.me/krlosexe_bot/Krlosexe';
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
