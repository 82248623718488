export const formatDecimal = (input: string): string => {
  const cleanInput = input.replace(/\D/g, '');

  if (cleanInput === '') return '';

  const numberValue = parseInt(cleanInput, 10);

  const numberString = numberValue.toString();

  if (numberString.length <= 2) {
    return `0.${numberString.padStart(2, '0')}`;
  }

  const integerPart = numberString.slice(0, numberString.length - 2);
  const decimalPart = numberString.slice(numberString.length - 2);

  return `${integerPart}.${decimalPart}`;
};
