import requests from 'helpers/requests';
import { useEffect, useState } from 'react';

type PostLinkData = {
  id: number;
  user_id: number;
  post_link: string;
  provider: 'Binance' | 'Youtube';
  status: 'Pending' | 'Done' | 'none';
  created_at: string;
  updated_at: string;
};

export const useExtraTasks = () => {
  const [data, setData] = useState<PostLinkData[]>([]);
  const [hasBoostBinance, sethasBoostBinance] = useState<any>(false);
  const [hasBoostYoutube, sethasBoostYoutube] = useState<any>(false);
  const [usedBoostBinance, setusedBoostBinance] = useState<any>(false);
  const [usedBoostYoutube, setusedBoostYoutube] = useState<any>(false);

  const store = async (data: { provider: string; post_link: string }) => {
    try {
      const response = await requests.post('extra/task', data);
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred';
      throw new Error(errorMessage);
    }
  };

  const get = async () => {
    try {
      const response = await requests.get('extra/task');
      setData(response.data);
    } catch (error: any) {}
  };

  const getActiveBoost = async () => {
    try {
      const response = await requests.get('get/active/boost');
      sethasBoostBinance(response.data.binance);
      sethasBoostYoutube(response.data.youtube);
      setusedBoostBinance(response.data.boost_stake_binance);
      setusedBoostYoutube(response.data.boost_stake_youtube);
    } catch (error: any) {}
  };

  useEffect(() => {
    get();
    getActiveBoost();
  }, []);
  const activeExtraTaskBoost = async (provider: 'Binance' | 'Youtube') => {
    try {
      const response = await requests.post('active/boost/extra/task', {
        provider,
      });
      getActiveBoost();
      return response.data;
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message || 'An unexpected error occurred';
      throw new Error(errorMessage);
    }
  };

  return {
    data,
    store,
    activeExtraTaskBoost,
    hasBoostBinance,
    hasBoostYoutube,
    usedBoostBinance,
    usedBoostYoutube,
  };
};
