import requests from 'helpers/requests';
import { useEffect, useState } from 'react';
import { Wallet } from 'types/wallet';
import { useToast } from '@context/ToastContext';
import { useNavigate } from 'react-router-dom';

type Contract = {
  user_id: number;
  amount: string;
  contract_day: number;
  contract_value: string;
  status: string;
  created_at: any;
  updated_at: string;
  id: number;
};

type StakeSummaryType = {
  stake_actives: {
    stake_started: {
      id: number;
      user_id: number;
      amount: string;
      contract_day: number;
      contract_value: number;
      status: string;
      created_at: string;
      updated_at: string;
    };
    current_reward: string;
    total_reward_estimated: string;
    days_elapsed: number;
  }[];
};
export const useStake = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();

  const [data, setData] = useState<Wallet>();
  const [stake, setStake] = useState<Contract>();
  const [stakeSummary, setStakeSummary] = useState<StakeSummaryType>();
  const [stakeFinish, setStakeFinish] = useState<any[]>([]);
  const [operationLimit, setOperationLimit] = useState<any>(false);
  const [loadLimit, setloadLimit] = useState<any>(false);

  const getBalance = async () => {
    try {
      const response = await requests.get('stake/balance');
      setData(response.data);
    } catch (error: any) {}
  };

  const getStake = async (contract: any) => {
    try {
      const response = await requests.get('stake?contract=' + contract.day);
      setStake(response.data);
    } catch (error: any) {
      setStake(undefined);
    }
  };

  const getOperationLimit = async (contract: any) => {
    try {
      setloadLimit(true);
      const response = await requests.get(
        'operation/limit?contract=' + contract.day,
      );
      setloadLimit(false);
      setOperationLimit(response.data);
    } catch (error: any) {
      setloadLimit(false);
      setOperationLimit(false);
    }
  };

  const getStakeSummary = async () => {
    try {
      const response = await requests.get('stake/sumary');
      setStakeSummary(response.data);
    } catch (error: any) {}
  };

  const getStakeFinish = async () => {
    try {
      const response = await requests.get('stake/finish');
      setStakeFinish(response.data);
    } catch (error: any) {}
  };

  const cancel = async (data: any) => {
    try {
      await requests.post('stake/cancel', data);
      getStakeSummary();
      getStakeFinish();
      showToast('successful operation', 'success');
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  const boost = async (data: any) => {
    try {
      await requests.post('stake/boost', data);
      getStakeSummary();
      getStakeFinish();
      alert('successful operation');
      navigate('/');
    } catch (error: any) {
      showToast('An error occurred', 'error');
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  type stakeStoreType = {
    amount: number | string;
    contract: {
      day: number;
      value: string;
    };
  };
  const store = async (data: stakeStoreType) => {
    try {
      const response = await requests.post('stake/store', data);
      showToast('successful operation', 'success');
      return response;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert('An error occurred');
      }
      return error;
    }
  };

  return {
    data,
    refresh: getBalance,
    store,
    stake,
    getStake,
    stakeSummary,
    getStakeSummary,
    getStakeFinish,
    stakeFinish,
    cancel,
    getOperationLimit,
    operationLimit,
    loadLimit,
    boost,
  };
};
