import Layout from '@components/layout';
import './style.css';
import Buttom from '@components/buttons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { useGame } from '@hooks/useGame';
import Icon from './assets/3.svg';
import Icon2 from './assets/4.svg';

function App() {
  const navigate = useNavigate();
  const { points, availableTickets, refresh, usedTicket } = useGame();

  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [showGame, setShowGame] = useState<boolean>(false);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const nextMidnight = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1),
      );

      //@ts-ignore
      const timeDifference = nextMidnight - now;

      const hours = String(
        Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
      ).padStart(2, '0');
      const minutes = String(
        Math.floor((timeDifference / (1000 * 60)) % 60),
      ).padStart(2, '0');
      const seconds = String(Math.floor((timeDifference / 1000) % 60)).padStart(
        2,
        '0',
      );

      setTimeRemaining(`${hours}:${minutes}:${seconds}`);
    };

    // Update the countdown every second
    const intervalId = setInterval(calculateTimeRemaining, 1000);

    // Calculate immediately on mount
    calculateTimeRemaining();

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <Layout icon={false} showBottomNav={true} showGoBack={false}>
      {showGame ? (
        <div>
          <button
            className="closegame"
            onClick={() => [setShowGame(false), refresh()]}
          >
            <i className="fas fa-times"></i>
          </button>
          <iframe
            src={`https://game.eventosvp.com.co/?user_id=${user.id}`}
          ></iframe>
        </div>
      ) : (
        <div id="airdrop">
          <div className="balance-container">
            <div className="balance-section">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={Icon2} width={40} height={40} alt="" />
                <div className="balance-amount">
                  &nbsp;{(points || 0).toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          <div className="info">
            <div>
              <div>
                <span>Next Tickets</span>
                <br />
                <span>{timeRemaining}</span>
              </div>
            </div>
            <div>
              <div>
                <span>Ticket Amount</span>
                <br />
                <span>
                  <i className="fas fa-ticket-alt"></i>{' '}
                  {availableTickets?.available}
                </span>
              </div>
            </div>
          </div>
          <br /> <br />
          <div className="content-poster">
            <img src={require('./assets/pngpi.png')} alt="" />

            {availableTickets && availableTickets.available > 0 && (
              <Buttom
                size="lg"
                label="Play"
                onClick={() => {
                  setShowGame(true);
                  usedTicket();
                }}
              />
            )}
          </div>
          <br />
          <br />
          <div className="info">
            <div className="item-info" onClick={() => navigate('/frenspoints')}>
              <i className="fas fa-user-plus"></i>
              <span>
                Invite friends and get extra tickets and 5% of the score
                obtained
              </span>
            </div>
            <div className="item-info">
              <i className="fas fa-search"></i>
              <span>Learn more about the $DMO token and its function</span>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default App;
