import Layout from '@components/layout';
import './style.css';

import { LineChart, Line, Tooltip, ResponsiveContainer } from 'recharts';

import { ReactComponent as ComercioIcon } from '@assets/icons/comercio.svg';
import { ReactComponent as BackIcon } from '@assets/icons/volver.svg';
import { ReactComponent as ReloadIcon } from '@assets/icons/reload.svg';
import { ReactComponent as FullScreenIcon } from '@assets/icons/pantalla-completa.svg';
import { ReactComponent as FunctionIcon } from '@assets/icons/funcion.svg';
import { ReactComponent as SettingIcon } from '@assets/icons/ajuste.svg';
import { ReactComponent as ToroIcon } from '@assets/icons/mercado-alcista.svg';
import { ReactComponent as OsoIcon } from '@assets/icons/soportar.svg';
import { useEffect, useRef, useState } from 'react';
import { InputText } from '@components/forms';
import Buttom from '@components/buttons';

function App() {
  const [showMarket, setShowMarket] = useState<boolean>(false);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = () => {
      if (!scrollContainer) return;
      //@ts-ignore
      const scrollPosition = scrollContainer.scrollLeft;

      const maxScrollLeft = //@ts-ignore
        scrollContainer.scrollWidth - scrollContainer.clientWidth;

      const scrollPercentage = (scrollPosition / maxScrollLeft) * 100;

      if (scrollPercentage >= 50) {
        setActiveIndex(1);
      } else {
        setActiveIndex(0);
      }
    };

    //@ts-ignore
    scrollContainer.addEventListener('scroll', handleScroll);

    return () => {
      if (scrollContainer) {
        //@ts-ignore
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef]);

  const data = [
    {
      name: 'Page A',
      usd: 2400,
    },
    {
      name: 'Page B',
      usd: 1398,
    },
    {
      name: 'Page B',
      usd: 2398,
    },
    {
      name: 'Page C',
      usd: 8800,
    },
    {
      name: 'Page C',
      usd: 2800,
    },
    {
      name: 'Page C',
      usd: 1800,
    },
    {
      name: 'Page C',
      usd: 2800,
    },
    {
      name: 'Page C',
      usd: 3800,
    },
    {
      name: 'Page C',
      usd: 7800,
    },
    {
      name: 'Page D',
      usd: 1908,
    },
    {
      name: 'Page E',
      usd: 8800,
    },
    {
      name: 'Page F',
      usd: 3800,
    },
    {
      name: 'Page G',
      usd: 4300,
    },
  ];
  //@ts-ignore
  return (
    <Layout
      title=""
      showBottomNav={true}
      showGoBack={false}
      showNotification={false}
      showWallet={true}
    >
      <div id="trade">
        <div className="head-container">
          <div className="section">
            <span className="head-label">Watch list</span>
            <i className="fas fa-angle-down"></i>
          </div>
        </div>
        {}
        <div className="scroll-container" ref={scrollContainerRef}>
          <div className="scroll-item">
            <div className="label">
              BTC/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">58,100.01</div>
          </div>
          <div className="scroll-item">
            <div className="label">
              BNB/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">548.01</div>
          </div>
          <div className="scroll-item">
            <div className="label">
              DMO/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">4,241</div>
          </div>
          <div className="scroll-item">
            <div className="label">
              BTC/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">3,112</div>
          </div>

          <div className="scroll-item">
            <div className="label">
              BTC/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">2,212</div>
          </div>
          <div className="scroll-item">
            <div className="label">
              BNB/USDT <i className="fas fa-star"></i>
            </div>
            <div className="amount">0,112</div>
          </div>
        </div>

        <div className="scroll-horizontal-dots">
          <div className={activeIndex === 0 ? 'active' : ''}> </div>
          <div className={activeIndex === 1 ? 'active' : ''}> </div>
        </div>

        <br />

        <div className="amount-section">
          <span>58,100.01</span>
          <br />
          <small>-1.35%</small>
        </div>
        <br />

        {!showMarket ? (
          <>
            <div className="content-chart">
              <div className="chart">
                <div className="tolbar">
                  <ComercioIcon width={20} height={20} />
                  <div className="custom-icon">
                    <span>1M</span>
                    <i className="fas fa-angle-down"></i>
                  </div>
                  <FunctionIcon width={20} height={20} />

                  <BackIcon width={15} height={15} />
                  <ReloadIcon width={15} height={15} />
                  <SettingIcon width={15} height={15} />
                  <FullScreenIcon width={14} height={14} />
                </div>
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <Tooltip
                      contentStyle={{
                        backgroundColor: '#333',
                        color: '#fff',
                        border: '1px solid #888',
                      }}
                      itemStyle={{
                        color: '#fff',
                      }}
                      labelStyle={{
                        color: '#ccc',
                      }}
                      includeHidden
                    />

                    <Line
                      type="bump"
                      dataKey="usd"
                      stroke="#3f88f8"
                      activeDot={{ r: 8 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="actions">
              <div
                className="item-action"
                onClick={() => setShowMarket(!showMarket)}
              >
                <ToroIcon width={20} height={20} />
                LONG
              </div>
              <div
                className="item-action"
                onClick={() => setShowMarket(!showMarket)}
              >
                <OsoIcon width={16} height={16} />
                SHORT
              </div>
            </div>
            <br />
            <br />

            <div className="open-orders">
              <p>Open orders</p>
              <i className="fas fa-angle-down"></i>
            </div>
          </>
        ) : (
          <div className="content-market">
            <div className="head-container">
              <div className="section">
                <span className="head-label">Market</span>
                <i className="fas fa-angle-down"></i>
              </div>
              <div className="close" onClick={() => setShowMarket(!showMarket)}>
                <i className="far fa-times-circle"></i>
              </div>
              <div className="form">
                <div className="input-group">
                  <InputText
                    placeholder="0.00"
                    size="md"
                    label="Amount"
                    value=""
                  />
                </div>

                <div className="inputs-orders">
                  <div className="input-group">
                    <InputText
                      placeholder="0.00"
                      size="md"
                      label="Stop Order"
                      value=""
                    />
                  </div>
                  <div className="input-group">
                    <InputText
                      placeholder="0.00"
                      size="md"
                      label="Target Order"
                      value=""
                    />
                  </div>
                </div>

                <div>
                  <label>Adjust leverage</label>
                  <div className="leverage">
                    <i className="fas fa-minus"></i>
                    <div>
                      <span>1x</span>
                    </div>
                    <i className="fas fa-plus"></i>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="btn-execute">
              <Buttom label="EXECUTE" size="lg" />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default App;
