import React from 'react';
import './style.css';
import LogoWhite from '@assets/logos/white.svg';
import { AppStateType, store } from '@redux/store';
import { isSingin } from '@redux/slices/initLoad/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Index: React.FC = () => {
  const { showlogout } = useSelector((state: AppStateType) => state.initLoad);
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('@tokenAuth');
    localStorage.removeItem('@userData');
    store.dispatch(isSingin(false));
    navigate('/');
  };
  return (
    <div className="content-left-menu">
      <img src={LogoWhite} alt="MiningOptions" className="left-menu-logo" />
      <div className="items-menu">
        <div className="section-item-menu">
          <span>LAUNCHPAD</span>
          <ul>
            <li
              className={`left-menu-item ${
                location.pathname === '/' ||
                location.pathname === '/stake' ||
                location.pathname === '/wallet' ||
                location.pathname === '/frens'
                  ? 'active'
                  : ''
              }`}
            >
              <Link to="/">
                <i className="fas fa-tachometer-alt"></i>
                DASHBOARD
              </Link>
            </li>
            <li
              className={`left-menu-item ${
                location.pathname === '/stake-summary' ? 'active' : ''
              }`}
            >
              <Link to="/stake-summary">
                <i className="fas fa-file-invoice"></i>STAKE SUMARY
              </Link>
            </li>
            <li
              className={`left-menu-item ${
                location.pathname === '/linking-accounts' ? 'active' : ''
              }`}
            >
              <Link to="/linking-accounts">
                <i className="fas fa-link"></i>LINKING ACCOUNTS
              </Link>
            </li>

            <li>
              <i className="fas fa-headset"></i>SUPPORT
            </li>
            <li>
              <i className="fas fa-sliders-h"></i>SETTINGS
            </li>
          </ul>
        </div>
      </div>

      {showlogout && (
        <button type="button" className="logout" onClick={logout}>
          LOGOUT
          <br />
          <i className="fas fa-sign-out-alt"></i>
        </button>
      )}
    </div>
  );
};

export default Index;
